/* Inherit color */
.c-inherit,
.c-inherit-all,
.c-inherit-all *,
.c-inherit-hover:hover,
.c-inherit-hover:hover * {
  color: inherit !important;
}

/* Purple */
.c-purple-prime {
  color: var(--purplePrime) !important;
}

.bg-purple-prime {
  background-color: var(--purplePrime) !important;
}

.c-purple-lighter {
  color: var(--purpleLighter) !important;
}

.bg-purple-lighter {
  background-color: var(--purpleLighter) !important;
}

.c-purple-light {
  color: var(--purpleLight) !important;
}

.bg-purple-light {
  background-color: var(--purpleLight) !important;
}

.c-purple-medium {
  color: var(--purpleMedium) !important;
}

.bg-purple-medium {
  background-color: var(--purpleMedium) !important;
}

.c-purple-dark {
  color: var(--purpleDark) !important;
}

.bg-purple-dark {
  background-color: var(--purpleDark) !important;
}

.c-purple-darker {
  color: var(--purpleDarker) !important;
}

.bg-purple-darker {
  background-color: var(--purpleDarker) !important;
}

.c-purple,
.c-purple-all,
.c-purple-all *,
.c-purple-hover:hover,
.c-purple-hover:hover *,
.c-purple-common {
  color: var(--purpleCommon) !important;
}

.bg-purple,
.bg-purple-common {
  background-color: var(--purpleCommon) !important;
}

/* Cyan */
.c-cyan-prime {
  color: var(--cyanPrime) !important;
}

.bg-cyan-prime {
  background-color: var(--cyanPrime) !important;
}

.c-cyan-lighter {
  color: var(--cyanLighter) !important;
}

.bg-cyan-lighter {
  background-color: var(--cyanLighter) !important;
}

.c-cyan-light {
  color: var(--cyanLight) !important;
}

.bg-cyan-light {
  background-color: var(--cyanLight) !important;
}

.c-cyan-medium {
  color: var(--cyanMedium) !important;
}

.bg-cyan-medium {
  background-color: var(--cyanMedium) !important;
}

.c-cyan-dark {
  color: var(--cyanDark) !important;
}

.bg-cyan-dark {
  background-color: var(--cyanDark) !important;
}

.c-cyan-darker {
  color: var(--cyanDarker) !important;
}

.bg-cyan-darker {
  background-color: var(--cyanDarker) !important;
}

.c-cyan,
.c-cyan-common {
  color: var(--cyanCommon) !important;
}

.bg-cyan,
.bg-cyan-common {
  background-color: var(--cyanCommon) !important;
}

/* Red */
.c-red-lighter {
  color: var(--redLighter) !important;
}

.bg-red-lighter {
  background-color: var(--redLighter) !important;
}

.c-red-light {
  color: var(--redLight) !important;
}

.bg-red-light {
  background-color: var(--redLight) !important;
}

.c-red-medium {
  color: var(--redMedium) !important;
}

.bg-red-medium {
  background-color: var(--redMedium) !important;
}

.c-red-dark {
  color: var(--redDark) !important;
}

.bg-red-dark {
  background-color: var(--redDark) !important;
}

.c-red-darker {
  color: var(--redDarker) !important;
}

.bg-red-darker {
  background-color: var(--redDarker) !important;
}

.c-red,
.c-red-common {
  color: var(--redCommon) !important;
}

.bg-red-common {
  background-color: var(--redCommon) !important;
}

/* Green */
.c-green-lighter {
  color: var(--greenLighter) !important;
}

.bg-green-lighter {
  background-color: var(--greenLighter) !important;
}

.c-green-light {
  color: var(--greenLight) !important;
}

.bg-green-light {
  background-color: var(--greenLight) !important;
}

.c-green-medium {
  color: var(--greenMedium) !important;
}

.bg-green-medium {
  background-color: var(--greenMedium) !important;
}

.c-green-dark {
  color: var(--greenDark) !important;
}

.bg-green-dark {
  background-color: var(--greenDark) !important;
}

.c-green-darker {
  color: var(--greenDarker) !important;
}

.bg-green-darker {
  background-color: var(--greenDarker) !important;
}

.c-green,
.c-green-common {
  color: var(--greenCommon) !important;
}

.bg-green-common {
  background-color: var(--greenCommon) !important;
}

/* White */
.c-white-lighter {
  color: var(--whiteLighter) !important;
}

.bg-white-lighter {
  background-color: var(--whiteLighter) !important;
}

.c-white-light {
  color: var(--whiteLight) !important;
}

.bg-white-light {
  background-color: var(--whiteLight) !important;
}

.c-white-medium {
  color: var(--whiteMedium) !important;
}

.bg-white-medium {
  background-color: var(--whiteMedium) !important;
}

.c-white-dark {
  color: var(--whiteDark) !important;
}

.bg-white-dark {
  background-color: var(--whiteDark) !important;
}

.c-white-darker {
  color: var(--whiteDarker) !important;
}

.bg-white-darker {
  background-color: var(--whiteDarker) !important;
}

.c-white,
.c-white-all,
.c-white-all *,
.c-white-hover:hover,
.c-white-hover:hover *,
.c-white-common {
  color: var(--whiteCommon) !important;
  fill: var(--whiteCommon) !important;
}

.bg-white,
.bg-white-common {
  background-color: var(--whiteCommon) !important;
}

/* Gray */
.c-gray-lighter {
  color: var(--grayLighter) !important;
}

.bg-gray-lighter {
  background-color: var(--grayLighter) !important;
}

.c-gray-light {
  color: var(--grayLight) !important;
}

.bg-gray-light {
  background-color: var(--grayLight) !important;
}

.c-gray-medium {
  color: var(--grayMedium) !important;
}

.bg-gray-medium {
  background-color: var(--grayMedium) !important;
}

.c-gray-dark {
  color: var(--grayDark) !important;
}

.bg-gray-dark {
  background-color: var(--grayDark) !important;
}

.c-gray-darker {
  color: var(--grayDarker) !important;
}

.bg-gray-darker {
  background-color: var(--grayDarker) !important;
}

.c-gray,
.c-gray-common {
  color: var(--grayCommon) !important;
}

.bg-gray,
.bg-gray-common {
  background-color: var(--grayCommon) !important;
}

/* Black */
.c-black-lighter {
  color: var(--blackLighter) !important;
}

.bg-black-lighter {
  background-color: var(--blackLighter) !important;
}

.c-black-light {
  color: var(--blackLight) !important;
}

.bg-black-light {
  background-color: var(--blackLight) !important;
}

.c-black-medium {
  color: var(--blackMedium) !important;
}

.c-black-medium-all,
.c-black-medium-all * {
  color: var(--blackMedium) !important;
  fill: var(--blackMedium) !important;
}

.bg-black-medium {
  background-color: var(--blackMedium) !important;
}

.c-black-dark {
  color: var(--blackDark) !important;
}

.bg-black-dark {
  background-color: var(--blackDark) !important;
}

.c-black,
.c-black-all,
.c-black-all *,
.c-black-hover:hover,
.c-black-hover:hover *,
.c-black-darker {
  color: var(--blackDarker) !important;
}

.bg-black-darker {
  background-color: var(--blackDarker) !important;
}

.c-black-common {
  color: var(--blackCommon) !important;
}

.bg-black-common {
  background-color: var(--blackCommon) !important;
}

/* Orange */
.c-orange {
  color: var(--orangeCommon) !important;
}

.c-orange-lighter {
  color: var(--orangeLighter) !important;
}

.c-orange-light {
  color: var(--orangeLight) !important;
}

.c-orange-medium {
  color: var(--orangeMedium) !important;
}

.c-orange-dark {
  color: var(--orangeDark) !important;
}

.c-orange-darker {
  color: var(--orangeDarker) !important;
}

.bg-orange {
  background-color: var(--orangeCommon) !important;
}

.bg-orange-lighter {
  background-color: var(--orangeLighter) !important;
}

.bg-orange-light {
  background-color: var(--orangeLight) !important;
}

.bg-orange-medium {
  background-color: var(--orangeMedium) !important;
}

.bg-orange-dark {
  background-color: var(--orangeDark) !important;
}

.bg-orange-darker {
  background-color: var(--orangeDarker) !important;
}

/* Yellow */
.c-yellow {
  color: var(--yellowCommon) !important;
}

.c-yellow-lighter {
  color: var(--yellowLighter) !important;
}

.c-yellow-light {
  color: var(--yellowLight) !important;
}

.c-yellow-medium {
  color: var(--yellowMedium) !important;
}

.c-yellow-dark {
  color: var(--yellowDark) !important;
}

.c-yellow-darker {
  color: var(--yellowDarker) !important;
}

.bg-yellow {
  background-color: var(--yellowCommon) !important;
}

.bg-yellow-lighter {
  background-color: var(--yellowLighter) !important;
}

.bg-yellow-light {
  background-color: var(--yellowLight) !important;
}

.bg-yellow-medium {
  background-color: var(--yellowMedium) !important;
}

.bg-yellow-dark {
  background-color: var(--yellowDark) !important;
}

.bg-yellow-darker {
  background-color: var(--yellowDarker) !important;
}

/* Blue */
.c-blue {
  color: var(--blueCommon) !important;
}

.c-blue-lighter {
  color: var(--blueLighter) !important;
}

.c-blue-light {
  color: var(--blueLight) !important;
}

.c-blue-medium {
  color: var(--blueMedium) !important;
}

.c-blue-dark {
  color: var(--blueDark) !important;
}

.c-blue-darker {
  color: var(--blueDarker) !important;
}

.bg-blue {
  background-color: var(--blueCommon) !important;
}

.bg-blue-lighter {
  background-color: var(--blueLighter) !important;
}

.bg-blue-light {
  background-color: var(--blueLight) !important;
}

.bg-blue-medium {
  background-color: var(--blueMedium) !important;
}

.bg-blue-dark {
  background-color: var(--blueDark) !important;
}

.bg-blue-darker {
  background-color: var(--blueDarker) !important;
}

.bg-main,
.bg-main-common {
  background-color: var(--bgMainCommon) !important;
}

.bg-main-lighter {
  background-color: var(--bgMainLighter) !important;
}

.bg-main-light {
  background-color: var(--bgMainLight) !important;
}

.bg-main-medium {
  background-color: var(--bgMainMedium) !important;
}

.bg-main-dark {
  background-color: var(--bgMainDark) !important;
}

.bg-main-darker {
  background-color: var(--bgMainDarker) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

/**
 * [2024-07-10][story_9166] @todo to deprecate following rules 
 */

.bg-0 {
  background-color: transparent !important;
}

.bg-a-02 {
  background-color: rgba(6, 7, 9, 0.02) !important;
}

.bg-a1 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-aaa {
  background-color: #aaa !important;
}

.bg-eee {
  background-color: #eee !important;
}

.bg-unset {
  background-color: unset !important;
}

.bg-a-red {
  background-color: rgba(255, 111, 111, 0.25) !important;
}

.c-lime {
  color: lime !important;
}

.c-success {
  color: limegreen !important;
}

.c-failure {
  color: crimson !important;
}

.c-orange,
.c-warning {
  color: orange !important;
}

.b-aaa {
  border: 1px solid #aaa !important;
}

.b-gray {
  border: 1px solid var(--grayCommon) !important;
}

.b-green-44FF00 {
  border: 1px solid #49ff00 !important;
}

.b-light-green {
  border: 1px solid rgba(0, 255, 0, 0.1) !important;
}

.b-light-red {
  border: 1px solid rgba(255, 0, 0, 0.1) !important;
}

.b-gray-lighter {
  border: var(--outlined) !important;
}

.b-dashed {
  border: 1px dashed #aaa !important;
}

.b-aaa {
  border: 1px solid #aaa !important;
}

.bg-hover:hover,
.bg-hover:hover > * {
  background-color: var(--purpleLighterSolid) !important;
}

.bg-purple-hover:hover {
  background-color: var(--purpleLighter) !important;
}

.bg-cyan-hover:hover {
  background-color: var(--cyanLighter) !important;
}
